import { FC, useEffect, useRef, useState } from "react";
import { formatPriceAndCurrency, getStaticConstantValue } from "../../../../helpers/utlis";
import useFetchOrders from "./hooks/useFetchOrders";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Address, OrderItem, Payment, paymentValues } from "./types";
import * as S from "./styles/orderDetailsStyles";
import { INFO_LIST, PAYMENT_DETAILS, SHIPMENT_DETAILS, ORDER_DETAILS_PAGE_LABELS, NO_DETAILS_FOUND, statusMap, CAFETERIA_PAYMENT_DETAILS } from "./constants";
import { capitalizeFirstLetter, getAddress, mergeProducts } from "./orderHelpers";
import apiList, { ALERT_STATUS, APPROVED_BUTTON_LABEL, CLICK_AND_COLLECT_METHOD_NAME, COUPON_LABEL, GIFT_CART_LABEL, OFFER_LABEL, PAYMENT_HAS_BEEN_UPDATED_SUCCESSFULLY, PAYMENT_STATUS_UPDATED_SUCCESSFULLY, PLEASE_ENTER_PAYMENT_REF_NUMBER, REJECTED_BUTTON_LABEL, SOMETHING_WENT_WRONG, USER_TYPE } from "../../../../lib/constant";
import useFetchData from "../../../../hooks/useFetchData";
import { CUSTOMER_PENDING_PAYMENT_ROUTE_PATH } from "../../../../routes/routes-constant";
import { Loading } from "../../../atoms/loading/Loading";
import { Box, Tab, Tabs } from "@mui/material";
import InvoiceDetails from "./InvoiceDetails";
import { doActionGet, doActionPut } from "../../../../helpers/httpRequest";
import { useNotification } from "../../../../hooks/useNotification";
import ShipmentDetails from "./ShipmentDetails";
import { add_payment_approval_info } from "../../../../helpers/GTM";
import { getLocalStorage } from "../../../../helpers/localStorageUtil";
import { CustomerOrderData } from "../../employee-order-requests/types/types";

const OrderDetails: FC = () => {
    const location = useLocation();
    const customer_id = location.state?.data ?? '';

    useEffect(() => {
        if (customer_id) {
            doActionGet({
                url: apiList.getInvoiceDetailsForEmployees(Number(orderId)),
                userType: USER_TYPE.Employee
            })?.then((resp: any) => {
                setInvoiceData(resp?.data);
            }
            ).catch((error: any) => {
                console.log("error msg", error);
                showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            })
        }
        else{
            doActionGet({
                url: apiList.getInvoiceDetails(Number(orderId)),
                userType: USER_TYPE.Customer
            })?.then((resp: any) => {
                setInvoiceData(resp?.data);
            }
            ).catch((error: any) => {
                console.log("error msg", error);
                showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            })
        }
    }, []);

    useEffect(() => {
        if (customer_id) {
            doActionGet({
                url: apiList.getShipmentDetailsForEmployees(Number(orderId)),
                userType: USER_TYPE.Employee
            })?.then((resp: any) => {
                setShipmentData(resp?.data);
            }
            ).catch((error: any) => {
                console.log("error msg", error);
                showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            })
        }
        else{
            doActionGet({
                url: apiList.getShipmentDetails(Number(orderId)),
                userType: USER_TYPE.Customer
            })?.then((resp: any) => {
                setShipmentData(resp?.data);
            }
            ).catch((error: any) => {
                console.log("error msg", error);
                showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            })
        }
    }, []);

    const navigate = useNavigate();
    const { orderId } = useParams()
    const { orders, isLoading } = useFetchOrders(customer_id, orderId);
    let { data: countries = [] } = useFetchData(apiList.getCountries);
    const [invoiceData, setInvoiceData] = useState<any>([]);
    const [shipmentData, setShipmentData] = useState<any>([]);
    const { items, created_at, status = '', store_name: purchasedFrom, customer_email: email,
        customer_firstname, customer_lastname, base_subtotal: subtotal,
        base_shipping_amount: shippingHandling, base_tax_amount: tax,
        base_grand_total: grandTotal,
        base_total_paid: totalPaid,
        base_total_due: totalDue, billing_address } = (orders[0] ?? {});
    const [poNumbers, setPoNumbers] = useState<{ [key: number]: string }>({});
    const isCafOrder = (orders[0] ?? {})?.store_id !== Number(getStaticConstantValue('store_id'));
    const originalDate = new Date(created_at);
    const formattedDate = originalDate.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });
    const customerName = `${customer_firstname} ${customer_lastname}`;
    const shippingAddress: Address = (orders[0] ?? {})?.extension_attributes?.shipping_assignments?.[0]?.shipping.address ?? {};
    const [tabValues, setTabValues] = useState<{ [key: string]: number }>({});
    const [categories, setCategories] = useState(["Order Details", "Invoice", "Shipment"]);
    const { showAlert } = useNotification();
    const [payments, setPayments] = useState<Payment[]>(
        (orders[0] ?? {})?.extension_attributes?.payment_list);
    const inputRefs = useRef<{ [key: number]: HTMLInputElement | null }>({});
    const [isApprovalPending, setIsApprovalPending] = useState(false);
    const couponCode = (orders[0] ?? {})?.coupon_code;
    const couponAmount = (orders[0] ?? {})?.discount_amount;

    useEffect(() => {
        if ((orders[0] ?? {})?.extension_attributes?.payment_list) {
            setPayments((orders[0] ?? {})?.extension_attributes?.payment_list);
            const isPendingApproval = (orders[0] ?? {})?.extension_attributes?.payment_list?.filter((payment: any) => {
                return (
                    payment?.status === "Approval Pending"
                )
            })?.length > 0 ? true : false;
            setIsApprovalPending(isPendingApproval);
        }
    }, [orders.length])

    if (isLoading) return <Loading />
    const purchaseFromwords = purchasedFrom?.split('\n');;
    const purchaseFromStore = purchaseFromwords?.slice(0, 1)?.join(' ');
    let paymentMethodTitle = (orders[0] ?? {})?.extension_attributes?.payment_additional_info?.filter((method: paymentValues) => { return method.key === "method_title" });
    paymentMethodTitle = (paymentMethodTitle && paymentMethodTitle.length > 0) ? paymentMethodTitle[0]?.value : "";

    const TabPanel: React.FC<{ children: React.ReactNode; value: number; index: number; className?: string }> = ({ children, value, index, className }) => {
        return (
            <div role="tabpanel" hidden={value !== index} className={className}>
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </div>
        );
    };

    const handleTabChange = (category: string) => (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValues({ ...tabValues, [category]: newValue });
    };

    const isPoNumber = () => {
        let paymentList = (orders[0] ?? {})?.extension_attributes?.payment_list?.length > 0 ? (orders[0] ?? {})?.extension_attributes?.payment_list.filter((item: any) => {
            return item?.payment_type === "Bank Transfer" && item?.po_number === null;
        }) : [];
        return paymentList?.length > 0 ? true : false;
    }


    const handleSubmit = (paymentData: Payment) => {
        if (inputRefs.current[paymentData?.entity_id]?.value !== "") {
            doActionPut({
                url: apiList.setPaymentInformation(paymentData?.entity_id, inputRefs.current[paymentData?.entity_id]?.value || ''),
                userType: USER_TYPE.Customer
            })?.then((resp: any) => {
                showAlert(PAYMENT_HAS_BEEN_UPDATED_SUCCESSFULLY, ALERT_STATUS.success);
                setPayments(prevPayments =>
                    prevPayments.map(payment =>
                        payment.entity_id === paymentData?.entity_id
                            ? { ...payment, po_number: inputRefs.current[paymentData?.entity_id]?.value || '' }
                            : payment
                    )
                );
            })?.catch((error: any) => {
                console.error("error msg", error);
                showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            })
        }
        else {
            showAlert(PLEASE_ENTER_PAYMENT_REF_NUMBER, ALERT_STATUS.error);
        }

        // // Optionally clear the input field
        // setPoNumbers(prev => {
        //   const { [id]: _, ...rest } = prev;
        //   return rest;
        // });
    };

    const handleApproveButton = (entityId: number, status: number, orderData: CustomerOrderData) => {
        if (entityId) {
            doActionPut({
                url: apiList.approvePendingPayment(entityId, status),
                userType: USER_TYPE.Employee
            })?.then((resp: any) => {
                if (resp) {
                    showAlert(PAYMENT_STATUS_UPDATED_SUCCESSFULLY, ALERT_STATUS.success);
                }
                if (status === 1) {
                    const updatedList = payments.map((payment) =>
                        payment.entity_id === entityId ? { ...payment, status: "Approved" } : payment
                    );
                    setPayments(updatedList);
                }
                else {
                    const updatedList = payments.map((payment) =>
                        payment.entity_id === entityId ? { ...payment, status: "Declined" } : payment
                    );
                    setPayments(updatedList);
                }
                add_payment_approval_info(status === 1 ? "approve" : "decline", getLocalStorage("employee_email"), orderData?.parent_id, orderData?.method, orderData?.amount_ordered, orderData?.amount_authorized, getStaticConstantValue('requested_by'));
            }
            ).catch((error: any) => {
                console.error("error msg", error);
                showAlert(SOMETHING_WENT_WRONG, ALERT_STATUS.error);
            })
        }
    }
    const orderDetailTopSection = () => {
        return (<div className="order-detail-section">
            <div className="formated-orderid"> {ORDER_DETAILS_PAGE_LABELS.orderNumber.en + (orders[0] ?? {})?.increment_id} </div>
            <div className="formated-date"> {formattedDate} </div>
            <div className="order-status-heading">{`${ORDER_DETAILS_PAGE_LABELS.orderStatus.en + ":"}`}<span className="status-value">{(status in statusMap) ? statusMap[status as keyof typeof statusMap] : capitalizeFirstLetter(status)}</span></div>
            <div className="customer-details">
                {/* TODO: store name will be change by BE */}
                <div className="text1">{`${ORDER_DETAILS_PAGE_LABELS.purchasedFrom.en}:`} <span className="customer-detail-value">{purchaseFromStore} Store</span> </div>
                <div className="text1">{`${ORDER_DETAILS_PAGE_LABELS.name.en}:`} <span className="customer-detail-value">{customerName}</span></div>
                <div className="text1">{`${ORDER_DETAILS_PAGE_LABELS.email.en}:`} <span className="customer-detail-value">{email}</span></div>
                {Boolean((orders[0] ?? {})?.extension_attributes?.is_gift) && <div className="text1"><span className="text1">{ORDER_DETAILS_PAGE_LABELS.noteLabel.en}</span><span className="customer-detail-value">{GIFT_CART_LABEL}</span></div>}
            </div>
        </div>)
    }

    const orderDetailBottomSection = () => {
        return (
            <div className="order-detail-section final-section">
                <div className="detail-heading">{ORDER_DETAILS_PAGE_LABELS.shipmentDetails.en}</div>
                <table className="shipment-table">
                    <thead >
                        <tr >
                            {SHIPMENT_DETAILS.map(title => <th key={title} className="newTittle">{title}</th>)}
                        </tr>
                    </thead>
                    <tbody className="order-information">
                        <tr>
                            {billing_address && <td>{getAddress(billing_address, countries)}</td>}
                            {shippingAddress && <td>{(orders[0] ?? {})?.extension_attributes?.shipping_method_name===CLICK_AND_COLLECT_METHOD_NAME?"NA":getAddress(shippingAddress, countries)}</td>}
                            <td>{(orders[0] ?? {})?.extension_attributes?.shipping_method_name}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="horiz-line"></div>
                <div className="payment-method">
                    <div className="detail-heading">{ORDER_DETAILS_PAGE_LABELS.paymentMethods.en}</div>
                    {(orders[0] ?? {})?.extension_attributes?.payment_list.length > 0 &&
                        (orders[0] ?? {})?.extension_attributes?.payment_list?.map((item: any) => {
                            return (
                                <div className="payment-method-list">
                                    <div className="payment-title">{item?.method_title}</div>
                                    <div className="payment-title">{formatPriceAndCurrency(Number(item?.payment_amount), 2)}</div>


                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    const cafeteriaOrderDetailSection = () => {
        const isPayLaterPayment = (orders[0] ?? {})?.payment?.method === "paylater" ? true : false;
        return (
            <>
                <div className="order-detail-section">
                    <table className="product-table">
                        <thead >
                            <tr className="table-info bottom-border table-info-margin ">
                                {INFO_LIST.map(title => <th key={title} className="text1 newTittle">{title}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {mergeProducts(items)?.map((item: OrderItem) => {
                                const { sku: itemSku, qty_ordered, name, base_price: price, base_row_total: subtotal } = item
                                let variantData: { [key: string]: string } = {}
                                item?.extension_attributes?.attribute_info?.forEach((variant: any) => {
                                    if(variant?.label!=="Configurable Variant"){
                                    variantData[variant.label] = variant.value
                                    }
                                })
                                return <tr key={itemSku + 'tr'} className="table-info ">
                                    <td className='text2'><tr>{name}</tr><tr> {variantData && Object.keys(variantData)?.length > 0 && <div className="variant-data">
                                        {"(" + Object.values(variantData)?.join('  ,  ') + ")"}
                                    </div>}</tr></td>
                                    <td className="text2 value">{itemSku}</td>
                                    <td className="text2">{formatPriceAndCurrency(price)}</td>
                                    <td className="text2">{qty_ordered}</td>
                                    <td className="text2">{formatPriceAndCurrency(item?.base_discount_amount?subtotal+item?.base_discount_amount:subtotal)}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='payment-details'>
                    <div className="amount-breakup">{CAFETERIA_PAYMENT_DETAILS.map(detail => (detail !== OFFER_LABEL) ? (<div key={detail} className="text3">{detail}</div>) : (couponAmount !== 0 && <div key={detail} className="text3">{detail + "(" + couponCode + ")"}</div>))}</div>
                    <div className="amount-value-breakup">
                        <div className="text3">{` ${formatPriceAndCurrency(subtotal)}`}</div>
                        <div className="text3">{` ${formatPriceAndCurrency(tax)}`}</div>
                        {couponAmount !== 0 && <div className="text3">{couponAmount ? "-" + (formatPriceAndCurrency(couponAmount * (-1))) : 0}</div>}
                        <div className="text3">{` ${formatPriceAndCurrency(grandTotal)}`}</div>
                    </div>
                </div>
                <div className="final-payment">
                    {!isPayLaterPayment ? <div className="text1 payment-text">{`${ORDER_DETAILS_PAGE_LABELS.totalPaid.en} : ${formatPriceAndCurrency(grandTotal ?? 0)}`}</div> : <div className="text1 payment-text">{`${ORDER_DETAILS_PAGE_LABELS.totalPaid.en} : ${formatPriceAndCurrency(totalPaid ?? 0)}`}</div>}
                    {isPayLaterPayment && <> <div className="text1 payment-text">{`${ORDER_DETAILS_PAGE_LABELS.totalDue.en} : ${formatPriceAndCurrency(totalDue ?? 0)}`}</div>
                    </>}
                    {isPayLaterPayment && <> <div className="text1 payment-text">{`${ORDER_DETAILS_PAGE_LABELS.balance.en} : -${formatPriceAndCurrency(Number(grandTotal) - Number(totalPaid ?? 0))}`}</div>
                    </>}
                </div>
                <div className="order-detail-section final-section">
                    <div className="method-name-section">
                        <div className="detail-heading">{ORDER_DETAILS_PAGE_LABELS.paymentMethods.en}</div>
                        {payments && payments?.length > 0 && <div className="payment-title">{payments[0]?.method_title}</div>}
                    </div>
                </div>
            </>
        )
    }
    return <S.Content>{orders?.length ?
        <>
            <div className="my-orders-heading">
                {!isCafOrder ? categories?.map((category, index) => {
                    if (index === 0) {
                        return (
                            <>
                                <Tabs
                                    value={tabValues[category] || 0}
                                    onChange={handleTabChange(category)}
                                    variant="fullWidth"
                                    centered
                                    className="tab-container"
                                >
                                    <Tab label="Order Details" className={`tab-label ${tabValues[category] === 0 ? 'tab-label-selected' : ''}`} />
                                    <Tab label="Invoice" className={`tab-label ${tabValues[category] === 1 ? 'tab-label-selected' : ''}`} />
                                    <Tab label="Shipment" className={`tab-label ${tabValues[category] === 2 ? 'tab-label-selected' : ''}`} />
                                </Tabs>
                                <div className="tabs-content">
                                    <TabPanel value={tabValues[category] || 0} index={0} className="tab-panel">
                                        <div className="order-detail-section">
                                            <div className="formated-orderid"> {ORDER_DETAILS_PAGE_LABELS.orderNumber.en + (orders[0] ?? {})?.increment_id} </div>
                                            <div className="formated-date"> {formattedDate} </div>
                                            <div className="order-status-heading">{`${ORDER_DETAILS_PAGE_LABELS.orderStatus.en + ":"}`}<span className="status-value">{(status in statusMap) ? statusMap[status as keyof typeof statusMap] : capitalizeFirstLetter(status)}</span></div>
                                            <div className="customer-details">
                                                {/* TODO: store name will be change by BE */}
                                                <div className="text1">{`${ORDER_DETAILS_PAGE_LABELS.purchasedFrom.en}:`} <span className="customer-detail-value">{purchaseFromStore} Store</span> </div>
                                                <div className="text1">{`${ORDER_DETAILS_PAGE_LABELS.name.en}:`} <span className="customer-detail-value">{customerName}</span></div>
                                                <div className="text1">{`${ORDER_DETAILS_PAGE_LABELS.email.en}:`} <span className="customer-detail-value">{email}</span></div>
                                                {Boolean((orders[0] ?? {})?.extension_attributes?.is_gift) && <div className="text1"><span className="text1">{ORDER_DETAILS_PAGE_LABELS.noteLabel.en}</span><span className="customer-detail-value">{GIFT_CART_LABEL}</span></div>}
                                            </div>
                                            <table className="product-table">
                                                <thead >
                                                    <tr className="table-info bottom-border table-info-margin ">
                                                        {INFO_LIST.map(title => <th key={title} className="text1 newTittle">{title}</th>)}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {mergeProducts(items)?.map((item: OrderItem) => {
                                                        const { sku: itemSku, qty_ordered, name, base_price: price, base_row_total: subtotal } = item
                                                        let variantData: { [key: string]: string } = {}
                                                        item?.extension_attributes?.attribute_info?.forEach((variant: any) => {
                                                            if(variant?.label!=="Configurable Variant"){
                                                            variantData[variant.label] = variant.value
                                                            }
                                                        })
                                                        return <tr key={itemSku + 'tr'} className="table-info ">
                                                            <td className='text2'><tr>{name}</tr><tr> {variantData && Object.keys(variantData)?.length > 0 && <div className="variant-data">
                                                                {"(" + Object.values(variantData)?.join('  ,  ') + ")"}
                                                            </div>}</tr></td>
                                                            <td className="text2 value">{itemSku}</td>
                                                            <td className="text2">{formatPriceAndCurrency(price)}</td>
                                                            <td className="text2">{qty_ordered}</td>
                                                            <td className="text2">{formatPriceAndCurrency(item?.base_discount_amount?subtotal+item?.base_discount_amount:subtotal)}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='payment-details'>
                                            <div className="amount-breakup">{PAYMENT_DETAILS.map(detail => (detail !== OFFER_LABEL) ? (<div key={detail} className="text3">{detail}</div>) : (couponAmount !== 0 && <div key={detail} className="text3">{detail + "(" + couponCode + ")"}</div>))}</div>                                            <div className="amount-value-breakup">
                                                <div className="text3">{` ${formatPriceAndCurrency(subtotal)}`}</div>
                                                <div className="text3">{` ${formatPriceAndCurrency(tax)}`}</div>
                                                <div className="text3">{` ${formatPriceAndCurrency(shippingHandling)}`}</div>
                                                {couponAmount !== 0 && <div className="text3">{couponAmount ? "-" + (formatPriceAndCurrency(couponAmount * (-1))) : 0}</div>}
                                                <div className="text3">{` ${formatPriceAndCurrency(grandTotal)}`}</div>
                                            </div>
                                        </div>
                                        <div className="final-payment">
                                            <div className="text1 payment-text">{`${ORDER_DETAILS_PAGE_LABELS.totalPaid.en} : ${formatPriceAndCurrency(totalPaid ?? 0)}`}</div>
                                            {totalDue > 0 && <> <div className="text1 payment-text">{`${ORDER_DETAILS_PAGE_LABELS.totalDue.en} : ${formatPriceAndCurrency(totalDue ?? 0)}`}</div>
                                            </>}
                                            {totalDue > 0 && <> <div className="text1 payment-text">{`${ORDER_DETAILS_PAGE_LABELS.balance.en} : -${formatPriceAndCurrency(Number(grandTotal) - Number(totalPaid ?? 0))}`}</div>
                                            </>}
                                        </div>
                                        <div className="order-detail-section final-section">
                                            <div className="detail-heading">{ORDER_DETAILS_PAGE_LABELS.shipmentDetails.en}</div>
                                            <table className="shipment-table">
                                                <thead >
                                                    <tr >
                                                        {SHIPMENT_DETAILS.map(title => <th key={title} className="newTittle">{title}</th>)}
                                                    </tr>
                                                </thead>
                                                <tbody className="order-information">
                                                    <tr>
                                                        {billing_address && <td>{getAddress(billing_address, countries)}</td>}
                                                        {shippingAddress && <td>{(orders[0] ?? {})?.extension_attributes?.shipping_method_name===CLICK_AND_COLLECT_METHOD_NAME?"NA":getAddress(shippingAddress, countries)}</td>}
                                                        <td>{(orders[0] ?? {})?.extension_attributes?.shipping_method_name}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="horiz-line"></div>
                                            <div className="payment-method">
                                                <div className="detail-heading">{ORDER_DETAILS_PAGE_LABELS.paymentMethods.en}</div>
                                                {payments && payments?.length > 0 &&
                                                    payments?.map((item: any) => {
                                                        return (
                                                            <div>
                                                                <div className="payment-method-list">
                                                                    <div className="payment-title">{item?.method_title}</div>
                                                                    <div className="payment-title">{item?.po_number ? "Ref#" + item?.po_number : ""}</div>
                                                                    <div className="payment-title">{formatPriceAndCurrency(Number(item?.payment_amount), 2)}</div>
                                                                    {Boolean(customer_id) && item?.status === "Approval Pending" ? <><button className="order-detail-btn " onClick={() => { handleApproveButton(item?.entity_id, 1, item) }}>{APPROVED_BUTTON_LABEL}</button>
                                                                        <button className="cancel-return-btn" onClick={() => { handleApproveButton(item?.entity_id, 0, item) }}>{REJECTED_BUTTON_LABEL}</button></> : <><div className="payment-title">{item?.status}</div><div></div></>}
                                                                </div>
                                                                <div>
                                                                    {item?.payment_type === "Bank Transfer" && item?.po_number === null && !Boolean(customer_id) && (
                                                                        <div className="payment-update-section">
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Fill in Transaction Number"
                                                                                className="payment-input"

                                                                                onFocus={(e) => e.target.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                                                                                ref={ref => (inputRefs.current[item?.entity_id] = ref)}
                                                                            />
                                                                            <button className="update-button-section" onClick={() => handleSubmit(item)}>
                                                                                Update
                                                                            </button>

                                                                        </div>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>

                                        {(totalDue > 0 && !isApprovalPending) && !Boolean(customer_id) && <button
                                            className="view-pay-button"
                                            onClick={() => { navigate(CUSTOMER_PENDING_PAYMENT_ROUTE_PATH, { state: { orderId: (orders[0] ?? {})?.entity_id, totalDue: totalDue } }) }}
                                        >
                                            {ORDER_DETAILS_PAGE_LABELS.payBalance.en}
                                        </button>}
                                    </TabPanel>
                                    <TabPanel value={tabValues[category] || 0} index={1} className="tab-panel">
                                        {invoiceData && invoiceData?.items?.length > 0 ? <>
                                            {orderDetailTopSection()}
                                            <InvoiceDetails invoiceData={invoiceData} />
                                            {orderDetailBottomSection()}
                                        </> : <div className="no-item-text"><span>{NO_DETAILS_FOUND}</span></div>}
                                    </TabPanel>
                                    <TabPanel value={tabValues[category] || 0} index={2} className="tab-panel">
                                        {shipmentData && shipmentData?.items?.length > 0 ? <>
                                            {orderDetailTopSection()}
                                            <ShipmentDetails invoiceData={shipmentData} />
                                            {orderDetailBottomSection()}
                                        </> : <div className="no-item-text"><span>{NO_DETAILS_FOUND}</span></div>}
                                    </TabPanel>
                                </div>
                            </>
                        )
                    }

                }) : <> {orderDetailTopSection()}{cafeteriaOrderDetailSection()}</>}

            </div>
        </> : !isLoading ? <div className="no-item-text"><span>{NO_DETAILS_FOUND}</span></div> : null}
    </S.Content>

}

export default OrderDetails

