import apiList, { ALERT_STATUS, AUTHENTICATION_SUCCESS, DONT_HAVE_ACCOUNT_SUBHEADING, LOGIN_BUTTON_Label, LOGIN_FAILED, LOGIN_TITLE, LOGIN_WITH_EMAIL_LABEL, LOGIN_WITH_PHONE_LABEL, OTP_SEND_MSG, SEND_OTP_LABEL, SIGN_UP_HEADING, USER_TYPE } from "../../../../lib/constant";
import * as S from "../styles/styles";
import logo from '../../../../globals/images/tresori-golden-logo.jpg';
import OrFrame from '../../../../globals/svg/orFrame.svg';
import { useEffect, useState } from "react";
import { NOTIFICATION_PERMISSION_PATH, OTP_CONFIRMATION_PATH, SCAN_ROUTE_PATH, SIGNUP_ROUTE_PATH } from "../../../../routes/routes-constant";
import { useNavigate } from "react-router-dom";
import { confirmSignIn, fetchUserAttributes, getCurrentUser, signIn } from 'aws-amplify/auth';
import { Loading } from "../../../atoms/loading/Loading";
import OtpConfirmation from "../otp-confirmation";
import { useNotification } from "../../../../hooks/useNotification";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../../../../helpers/localStorageUtil";
import { manageIndexedDB } from "../../../../helpers/indexedDB";
import { UserAttributeInterface } from "../types/types";
import { useGlobalState } from "../../../../Context/GlobalStateContext";
import { useAuth } from "../../../../Context/AuthContext";
import { requestPushPermission } from "../../../../helpers/NotificationHandler/subscribeNotification";
import { PDP_REDIRECT_PATH } from "../constant";
import { doActionPost } from "../../../../helpers/httpRequest";
import { setStaticConstants } from "../../../../helpers/utlis";
import { CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js';
import * as X from "aws-amplify/auth";
import { FormControl, MenuItem, Select } from "@mui/material";

const LoginWithOtp = () => {
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate();
    const [otpSent,setOtpSent] = useState(false);
    const { showAlert } = useNotification();
    const [userAttributes, setUserAttributes] = useState<UserAttributeInterface>({
        email: "",
        fname: "",
        lname: "",
        phoneNumber:""
      });
      const [cognitoUserId, setCognitoUserId] = useState<String>("");
      const { isUserLoggedIn, loginUser } = useAuth();
      const { setCustomerData } = useGlobalState();
      const [showLoggedInButton, setShowLoggedInButton] = useState(true);
      const pdp_redirect_sku = sessionStorage.getItem('pdp_redirect_sku')
      const [isNotifPermission,setIsNotifPermission] = useState(false);
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [emailError, setEmailError] = useState('');
      const [phoneError, setPhoneError] = useState('');
      const [countryCodes,setCountryCodes] = useState<string[]>();
     const [phoneCode,setPhoneCode] = useState("+971");

      const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const validatePhoneNumber = (phone: string) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phone);
      };
    
      const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setPhoneNumber(''); // Clear phone number if email is being entered
        setEmailError(''); 
        setPhoneError('');   // Reset error on change
      };
    
      const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
        setEmail(''); // Clear email if phone number is being entered
        setPhoneError('');  // Reset error on change
        setEmailError('');
      };
    
      const handleEmailBlur = () => {
        if (email && !validateEmail(email)) {
          setEmailError('Please enter a valid email');
        }
      };
    
      const handlePhoneBlur = () => {
        if (phoneNumber && !validatePhoneNumber(phoneNumber)) {
          setPhoneError('Please enter a valid 10-digit phone number');
        }
      };

      useEffect(()=>{
        const countries: CountryCode[] = getCountries() as CountryCode[];
        
        let  countryCodesData: string[] = [];
    
        countries.forEach((country: CountryCode) => {
          if(!countryCodesData.includes(`+${getCountryCallingCode(country)}`)){
            countryCodesData.push(`+${getCountryCallingCode(country)}`);
          }
        });
        setCountryCodes(countryCodesData);
      },[])

    useEffect(() => {
        if (getLocalStorage('access-token')) {
          removeLocalStorage('access-token');
          manageIndexedDB({ key: 'accessToken', toDelete: true })
          //need to call employee sign out function #todo
        }
        const checkUserAuthentication = async () => {
          try {
            // Check if user is signed in
            const currentUser = await getCurrentUser();
            setCognitoUserId(currentUser?.username);
            getUserAttributes();
          } catch (err) {
            setShowLoggedInButton(true);
            // User is not signed in
            console.log("User is not signed in", err);
          }
        };
    
        const getUserAttributes = async () => {
          try {
            const attributes: any = await fetchUserAttributes();
            setUserAttributes({
              email: attributes['custom:encrypted_email'] || "",
              fname: attributes?.given_name || "",
              lname: attributes?.family_name || "",
              phoneNumber:attributes?.phone_number||""
            });
            console.log("attribute=>", attributes)
            setShowLoggedInButton(false);
          } catch (error) {
            setShowLoggedInButton(true);
            console.error("Error fetching user attributes:", error);
          }
        };
        checkUserAuthentication();
      }, []);
      useEffect(()=>{
        if(window?.Notification?.permission==="granted" || window?.Notification?.permission==="denied"){
          setIsNotifPermission(true);
        }
        },[])
        useEffect(()=>{
          if(isUserLoggedIn && !showLoggedInButton){
            if(isNotifPermission){
              requestPushPermission(getLocalStorage('cognitoId'));
                if (pdp_redirect_sku) {
                        navigate(PDP_REDIRECT_PATH(pdp_redirect_sku))
                        sessionStorage.removeItem('pdp_redirect_sku')
                }
                else navigate(SCAN_ROUTE_PATH)
             }
            else{
              navigate(NOTIFICATION_PERMISSION_PATH);
            }
          }
        },[isUserLoggedIn,showLoggedInButton])
        
        useEffect(() => {
            if (userAttributes?.email && !getLocalStorage('user-token') && !isUserLoggedIn) {
              const payload = {
                "customer": {
                  "email": userAttributes.email,
                  "firstname": userAttributes.fname,
                  "lastname": userAttributes.lname
                },
                "cognito_user_id": cognitoUserId,  
                "phone_number":userAttributes?.phoneNumber
              }
        
              setCustomerData(payload);
        
              doActionPost({
                url: apiList.userLogin,
                data: payload
              })?.then(async (resp: any) => {
                setLocalStorage('user-token', resp.data);
                manageIndexedDB({ key: 'userToken', value: resp.data })
                manageIndexedDB({ key: 'accessToken', toDelete: true })
                setLocalStorage("cognitoId", JSON.stringify(cognitoUserId));
                loginUser();
                showAlert(AUTHENTICATION_SUCCESS, ALERT_STATUS.success);
                await setStaticConstants(USER_TYPE.Customer);
                if(isNotifPermission){
                 await  requestPushPermission(getLocalStorage('cognitoId'))
                  if (pdp_redirect_sku) {
                  navigate(PDP_REDIRECT_PATH(pdp_redirect_sku))
                  sessionStorage.removeItem('pdp_redirect_sku')
                }
                else navigate(SCAN_ROUTE_PATH)
               }
              else{
                navigate(NOTIFICATION_PERMISSION_PATH);
              }
    
              }
              ).catch((error: any) => {
                setShowLoggedInButton(true);
                console.log("error msg", error);
                showAlert(LOGIN_FAILED, ALERT_STATUS.error);
                //TODO:need to add error notification
              })
            }
          }, [userAttributes?.email]);
        

 
    const senOtpHandler =async()=>{
        try{
            if(emailError==="" && phoneError===""){
                setLoading(true);
                const response = await signIn({ 
                    username:email?email:phoneCode+phoneNumber,
                    options: {
                        clientMetadata: {}, // Optional, an object of key-value pairs which can contain any key and will be passed to your Lambda trigger as-is.
                        authFlowType:"CUSTOM_WITHOUT_SRP"
                      }
                 });
               setLoading(false);
               if(response?.nextStep?.signInStep==="CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"){
                setOtpSent(true);
               }
            }
        }
        catch (err:any) {
            setLoading(false);
            showAlert(err?.message, ALERT_STATUS.error);
            console.log("User is not signed in", err);
        }
       
        //navigate(OTP_CONFIRMATION_PATH)
    }
  
  

    
    return (
        <S.Content>
            {loading && <Loading/>}
            <div className="login-container">
            <div className="login-container-header">
                <img className="login-logo" src={logo}></img>
                <>
                <h3 className="login-title">
                    {LOGIN_TITLE}
                </h3>
                </>
            </div>
            {!otpSent ?<>
            <div className="login-button-container login-content-section">
             <div><input type="text" className="input-field" value={email} onChange={handleEmailChange}
              onBlur={handleEmailBlur} placeholder={LOGIN_WITH_EMAIL_LABEL}/>  {emailError && <p className="error-msg">{emailError}</p>}</div>
              <div className="or-frame">
              <img src={OrFrame} alt="OR" />
            </div>
            <div>
            <div className="phone-input-container">
              <FormControl fullWidth className="title-dropdown" size="small">
                <Select
                  labelId="title-label"
                  id="title"
                  name="title"
                  value={phoneCode}
                  className="input-field select-field"
                  onChange={(e) => setPhoneCode(e.target.value)}
                >
                  {countryCodes?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <input
                type="text"
                className="input-fields"
                value={phoneNumber}
                onChange={handlePhoneChange}
                 onBlur={handlePhoneBlur}
                placeholder={LOGIN_WITH_PHONE_LABEL}
              />
              </div>
              {phoneError && <p className="error-msg">{phoneError}</p>}</div>
            </div>
           
            <div className="subtext-container">
                {DONT_HAVE_ACCOUNT_SUBHEADING}<span className="subtext-heading" onClick={()=>{navigate(SIGNUP_ROUTE_PATH)}}>{SIGN_UP_HEADING}</span>
            </div>
            <div className="submit-button-container">
                <button className="submit-button" disabled={false} onClick={senOtpHandler} id="login-gmail">{SEND_OTP_LABEL}</button>
            </div>
            </>:
            <OtpConfirmation username={email?email:phoneNumber}/>
            }
          </div>
        </S.Content>
    );
}

export default LoginWithOtp;


