import React, { useEffect, useRef, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { doActionGet, doActionPut } from '../../../../helpers/httpRequest';
import apiList, { ALERT_STATUS, PLEASE_ENTER_REASON, PLEASE_ENTER_RETURN_REFERENCE_NUMBER, PURCHASE_ORDER_CODE, USER_TYPE } from '../../../../lib/constant';
import PrintIcon from '@mui/icons-material/Print';
import * as S from './styles/styles';
import * as N from '../new-list/styles/styles';
import { InfoValue } from './styles/TypographyStyles';
import { formatPriceAndCurrency, isCafeteriaManager } from '../../../../helpers/utlis';
import { EMPLOYEE_CHECKOUT_PAGE_ROUTE_PATH } from '../../../../routes/routes-constant';
import { calculateSubTotal, calculateTaxTotal, capitalizeFirstLetter, getDisplayStatus } from '../helpers';
import { useNotification } from '../../../../hooks/useNotification';
import ReactToPrint from 'react-to-print';
import { formatDate, mergeProducts } from '../../my-account/my-orders/orderHelpers';
import { statusMap } from '../../my-account/my-orders/constants';
import CancelModalPopUp from '../../../atoms/cancel-modal';

const EmployeeOrderDetails: React.FC = () => {
    const location = useLocation();
    const [orderData, setOrderData] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { showAlert } = useNotification();
    const componentRef = useRef<any>(null);
    const buttonRef = React.useRef<HTMLDivElement>(null);
    const [isModalOpen,setIsModalOpen] = useState(false);
    
    const fetchOrderDetails = async () => {
        const orderId = location.state?.orderId;

        if (!orderId) {
            console.error('No order ID provided in location state');
            showAlert('No order ID provided', ALERT_STATUS.error);
            setLoading(false);
            return;
        }

        try {
            console.log('Fetching order details for ID:', orderId);
            const response: any = await doActionGet({
                url: apiList.manageOrderDetails + orderId,
                userType: USER_TYPE.Employee
            });

            console.log('API response:', response.data);

            if (response.status !== 200) {
                console.error('API response not OK:', response);
                throw new Error(`Failed to fetch order details: ${response.statusText}`);
            }

            setOrderData(response.data);
            setLoading(false);
        } catch (err) {
            console.error('Error in fetchOrderDetails:', err);
            showAlert(`An error occurred while fetching order details: ${(err as Error).message}`, ALERT_STATUS.error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrderDetails();
    }, [location.state?.orderId]);

    if (loading) {
        return (
            <S.OrderDetailsContainer>
                <S.OrderHeader>Order Details</S.OrderHeader>
                <S.LoadingContainer>
                    {/* <CircularProgress size={40} /> */}
                </S.LoadingContainer>
            </S.OrderDetailsContainer>
        );
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!orderData) {
        return <Typography>No order data available</Typography>;
    }

    const handlePrint = () => {
        if (buttonRef && buttonRef.current) {
            buttonRef.current.click();
          }
        //window.print();
    };

    const handlePayment = () => {
        // if (orderData?.status === "complete") {
            // navigate(EMPLOYEE_CHECKOUT_PAGE_ROUTE_PATH, {
            //     state: {
            //         orderId: orderData?.entity_id,
            //         isSecondPayment: true,
            //         subTotal: calculateSubTotal(orderData),
            //         tax: calculateTaxTotal(orderData)
            //     }
            // });
            navigate(EMPLOYEE_CHECKOUT_PAGE_ROUTE_PATH, { 
                state: { 
                  orderId: orderData.entity_id, 
                  isSecondPayment: true,
                  subTotal: calculateSubTotal(orderData),
                  tax: calculateTaxTotal(orderData)
                } 
              });
            return;
       // }
    }

    const orderCancelHandler = async(reason:string,refNo:string,isCardPayment:boolean) =>{
        if(isCardPayment && refNo===""){
            showAlert(PLEASE_ENTER_RETURN_REFERENCE_NUMBER, ALERT_STATUS.error)
          }
          else if(reason===""){
            showAlert(PLEASE_ENTER_REASON, ALERT_STATUS.error)
          }
          else{
        try {
            const data: any = {
                "orderReturnCancelRequest": {
                    "reason": reason
                }
            };
            
            // Conditionally add `pos_txn_ref` if `refNo` has a value
            if (refNo) {
                data.orderReturnCancelRequest.pos_txn_ref = refNo;
            }
            const response = await doActionPut({
                url: apiList.cancelOrReturnOrder(location.state?.orderId, true),
                userType: USER_TYPE.Employee,
                data:data
            })
            if (response?.data) {
                showAlert(`Order cancellation successful`, ALERT_STATUS.success)
                await fetchOrderDetails();
               
            }
        }
        catch (err) { showAlert(`Order cancellation failed!`, ALERT_STATUS.error) }
        finally {setIsModalOpen(false)}
    }
    }

    const {
        increment_id,
        created_at,
        status,
        items,
        subtotal,
        tax_amount,
        grand_total,
        total_due,
        payment,
        extension_attributes,
        coupon_code,
        discount_amount
    } = orderData;

    const isPaid = extension_attributes?.is_open === false;
    const isCreditCardPayment = extension_attributes?.payment_list?.filter((payment:any)=>{return payment?.method===PURCHASE_ORDER_CODE})?.length>0?true:false;

    return (
        <S.PrintWrapper className="print-content" ref={componentRef}>
            <CancelModalPopUp isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)} onCancel={orderCancelHandler} isCardPayment={isCreditCardPayment}/>
            <style>{S.globalPrintStyles}</style>
            <S.OrderDetailsContainer>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <S.OrderHeader>Order Details</S.OrderHeader>
                    <S.PrintButton onClick={handlePrint} startIcon={<PrintIcon />}>
                        Print
                    </S.PrintButton>
                </Box>
                <S.OrderInfo>
                    <S.OrderInfoRow>
                        <S.OrderInfoValue>{formatDate(created_at)}</S.OrderInfoValue>
                       {(extension_attributes?.is_available_for_cancel && isCafeteriaManager()) ?<N.Content> <div className="copy-section" onClick={()=>setIsModalOpen(true)}>Cancel Order</div></N.Content>:<></>}
                    </S.OrderInfoRow>
                    <S.Divider />
                    <S.OrderInfoRow>
                        <S.OrderInfoLabel>Order: <InfoValue>{increment_id}</InfoValue></S.OrderInfoLabel>
                    </S.OrderInfoRow>
                    <S.OrderInfoRow>
                        <S.OrderInfoLabel>Order Status: <InfoValue>{(status in statusMap) ? statusMap[status as keyof typeof statusMap] : capitalizeFirstLetter(status)}
                            </InfoValue>
                            </S.OrderInfoLabel>
                        <S.OrderInfoValue>{isPaid ? 'Paid' : 'Unpaid'}</S.OrderInfoValue>
                    </S.OrderInfoRow>
                    {extension_attributes?.delivery_info?.type ?<S.OrderInfoRow>
                        <S.OrderInfoLabel>Order Type: <InfoValue>{extension_attributes?.delivery_info?.type===1?"Eat In "+(extension_attributes?.delivery_info?.notes?"("+extension_attributes?.delivery_info?.notes+")":""):"Take Away"}</InfoValue></S.OrderInfoLabel>
                    </S.OrderInfoRow>:""}
                    <S.Divider />
                </S.OrderInfo>
                <S.ProductTable>
                    <S.ProductTableHeader>
                        <S.ProductTableHeaderCell>Product name</S.ProductTableHeaderCell>
                        <Box display="flex" justifyContent="space-between" width="191px">
                            <S.ProductTableHeaderCell>Price</S.ProductTableHeaderCell>
                            <S.ProductTableHeaderCell>Qty</S.ProductTableHeaderCell>
                            <S.ProductTableHeaderCell>Subtotal</S.ProductTableHeaderCell>
                        </Box>
                    </S.ProductTableHeader>
                    {mergeProducts(items)?.map((item: any, index: number) => (
                        <S.ProductRow key={index}>
                            <S.ProductCell>{item?.name}</S.ProductCell>
                            <Box display="flex" justifyContent="space-between" width="191px">
                                <S.ProductCell>{formatPriceAndCurrency(item?.price)}</S.ProductCell>
                                <S.ProductCell>Ordered: {item?.qty_ordered}</S.ProductCell>
                                <S.ProductCell> {formatPriceAndCurrency(item?.base_discount_amount?item?.row_total+item?.base_discount_amount:item?.row_total)}</S.ProductCell>
                            </Box>
                        </S.ProductRow>
                    ))}
                </S.ProductTable>
                <S.TotalSection>
                    <S.TotalRow>
                        <S.TotalLabel>Subtotal</S.TotalLabel>
                        <S.TotalValue>{formatPriceAndCurrency(subtotal)}</S.TotalValue>
                    </S.TotalRow>
                    <S.TotalRow>
                        <S.TotalLabel>Tax</S.TotalLabel>
                        <S.TotalValue>{formatPriceAndCurrency(tax_amount)}</S.TotalValue>
                    </S.TotalRow>
                    {coupon_code!=="" &&discount_amount!==0 && <S.TotalRow>
                        <S.TotalLabel>Offer({coupon_code})</S.TotalLabel>
                        <S.TotalValue>{discount_amount? "-"+(formatPriceAndCurrency(discount_amount * (-1))):0}</S.TotalValue>
                    </S.TotalRow>}
                    <S.TotalRow>
                        <S.TotalLabel>Grand Total</S.TotalLabel>
                        <S.TotalValue>{formatPriceAndCurrency(grand_total)}</S.TotalValue>
                    </S.TotalRow>
                </S.TotalSection>
                {isPaid?<S.TotalDueRow>
                    <S.TotalDueLabel>Total</S.TotalDueLabel>
                    <S.TotalDueValue>{formatPriceAndCurrency(grand_total)}</S.TotalDueValue>
                </S.TotalDueRow>: <S.TotalDueRow>
                    <S.TotalDueLabel>Total due</S.TotalDueLabel>
                    <S.TotalDueValue>{formatPriceAndCurrency(total_due)}</S.TotalDueValue>
                </S.TotalDueRow>}
                <S.PaymentMethodSection>
                    <S.PaymentMethodLabel>Payment Method</S.PaymentMethodLabel>
                    <S.PaymentMethodInfo>
                        <S.PaymentMethodValue>{extension_attributes?.payment_additional_info && extension_attributes?.payment_additional_info?.length>0 && extension_attributes?.payment_additional_info?.find((item:any)=>{return item?.key==="method_title"})?.value }</S.PaymentMethodValue>
                        <S.PaymentMethodValue>{formatPriceAndCurrency(payment.amount_ordered)}</S.PaymentMethodValue>
                        {/* {extension_attributes?.payment_list[0].status && (
                            <S.PaymentMethodValue>{extension_attributes?.payment_list[0].status}</S.PaymentMethodValue>
                        )} */}
                    </S.PaymentMethodInfo>
                </S.PaymentMethodSection>
                {!isPaid && (
                    <S.PaymentButton onClick={handlePayment}>Complete Payment</S.PaymentButton>
                )}
            </S.OrderDetailsContainer>
            <ReactToPrint
                trigger={() => <div ref={buttonRef}></div>}
                content={() => componentRef.current}
                //pageStyle={() => pageStyle(qty)}
                />
        </S.PrintWrapper>
    );
};

export default EmployeeOrderDetails;