import React, { useEffect, useState } from "react";
import apiList, { ALERT_STATUS, APPLE_LOGIN_BUTTON_LABEL, AUTHENTICATION_SUCCESS, GOOGLE_LOGIN_BUTTON_LABEL, LOGIN_FAILED, LOGIN_TERMS_AND_POLICY, LOGIN_TITLE, LOGIN_WITH_OTP_LABEL, PRIVACY_AND_POLICY_CTA, TERMS_AND_CONDITION_CTA, USER_TYPE } from "../../../lib/constant";
import * as S from "./styles/styles";
import { fetchUserAttributes, signInWithRedirect, getCurrentUser } from "aws-amplify/auth";
import { doActionPost } from "../../../helpers/httpRequest";
import { clearPreviousLoginData, getLocalStorage, removeLocalStorage, setLocalStorage } from "../../../helpers/localStorageUtil";
import logo from './../../../globals/images/tresori-golden-logo.jpg';
import { useNavigate } from "react-router-dom";
import { EMPLOYEE_LOGIN_ROUTE_PATH, LOGIN_PRIVACY_POLICY_ROUTE_PATH, LOGIN_TERMS_AND_CONDITION_ROUTE_PATH, LOGIN_WITH_OTP_PATH, NOTIFICATION_PERMISSION_PATH, SCAN_ROUTE_PATH } from "../../../routes/routes-constant";
import { UserAttributeInterface } from "./types/types";
import { useNotification } from "../../../hooks/useNotification";
import { setStaticConstants } from "../../../helpers/utlis";
import BottomLogoInGrey from '../../../globals/images/Logo/BottomLogoInGrey.svg';
import { setPageToFullHeight } from "../../../helpers/style";
import '../../../awsConfig';
import { useAuth } from "../../../Context/AuthContext";
import { useGlobalState } from "../../../Context/GlobalStateContext";
import { requestPushPermission } from "../../../helpers/NotificationHandler/subscribeNotification";
import { manageIndexedDB } from "../../../helpers/indexedDB";
import { ReactComponent as GoogleIcon } from '../../../globals/images/icons/akar-icons_google-fill.svg';
import { ReactComponent as AppleIcon } from '../../../globals/images/icons/ic_outline-apple.svg';
import OrFrame from '../../../globals/svg/orFrame.svg';
import { LOGIN_CONSTANTS, PDP_REDIRECT_PATH } from "./constant";

const Login: React.FC = () => {
  const [userAttributes, setUserAttributes] = useState<UserAttributeInterface>({
    email: "",
    fname: "",
    lname: "",
    phoneNumber:""
  });
  const [cognitoUserId, setCognitoUserId] = useState<String>("");
  const navigate = useNavigate();
  const { showAlert } = useNotification();
  const { isUserLoggedIn, loginUser } = useAuth();
  const { setCustomerData } = useGlobalState();
  const [showLoggedInButton, setShowLoggedInButton] = useState(true);
  const pdp_redirect_sku = sessionStorage.getItem('pdp_redirect_sku')
  const [isNotifPermission,setIsNotifPermission] = useState(false);
  // useEffect(() => {
  //   if (isUserLoggedIn && cognitoUserId) {
  //     if (pdp_redirect_sku) {
  //       navigate(PDP_REDIRECT_PATH(pdp_redirect_sku))
  //       sessionStorage.removeItem('pdp_redirect_sku')
  //     }
  //     else navigate(SCAN_ROUTE_PATH)
  //   }
  // }, [isUserLoggedIn, cognitoUserId]);

  useEffect(() => {
    if (getLocalStorage('access-token')) {
      removeLocalStorage('access-token');
      manageIndexedDB({ key: 'accessToken', toDelete: true })
      //need to call employee sign out function #todo
    }
    const checkUserAuthentication = async () => {
      try {
        // Check if user is signed in
        const currentUser = await getCurrentUser();
        setCognitoUserId(currentUser?.username);
        getUserAttributes();
      } catch (err) {
        setShowLoggedInButton(true);
        // User is not signed in
        console.log("User is not signed in", err);
      }
    };

    const getUserAttributes = async () => {
      try {
        const attributes: any = await fetchUserAttributes();
        setUserAttributes({
          email: attributes['custom:encrypted_email'] || "",
          fname: attributes?.given_name || "",
          lname: attributes?.family_name || "",
          phoneNumber:attributes?.phone_number || ""
        });
        console.log("attribute=>", attributes)
        setShowLoggedInButton(false);
      } catch (error) {
        setShowLoggedInButton(true);
        console.error("Error fetching user attributes:", error);
      }
    };
    checkUserAuthentication();
  }, []);

  useEffect(()=>{
  if(window?.Notification?.permission==="granted" || window?.Notification?.permission==="denied"){
    setIsNotifPermission(true);
  }
  },[])
  useEffect(()=>{
    if(isUserLoggedIn && !showLoggedInButton){
      if(isNotifPermission){
        requestPushPermission(getLocalStorage('cognitoId'));
          if (pdp_redirect_sku) {
                  navigate(PDP_REDIRECT_PATH(pdp_redirect_sku))
                  sessionStorage.removeItem('pdp_redirect_sku')
          }
          else navigate(SCAN_ROUTE_PATH)
       }
      else{
        navigate(NOTIFICATION_PERMISSION_PATH);
      }
    }
  },[isUserLoggedIn,showLoggedInButton])

  useEffect(() => {
    if (userAttributes?.email && !getLocalStorage('user-token') && !isUserLoggedIn) {

      const payload = {
        "customer": {
          "email": userAttributes.email,
          "firstname": userAttributes.fname,
          "lastname": userAttributes.lname
        },
        "cognito_user_id": cognitoUserId,
        "phone_number":userAttributes?.phoneNumber
      }

      setCustomerData(payload);

      doActionPost({
        url: apiList.userLogin,
        data: payload
      })?.then(async (resp: any) => {
        setLocalStorage('user-token', resp.data);
        manageIndexedDB({ key: 'userToken', value: resp.data })
        manageIndexedDB({ key: 'accessToken', toDelete: true })
        setLocalStorage("cognitoId", JSON.stringify(cognitoUserId));
        loginUser();
        showAlert(AUTHENTICATION_SUCCESS, ALERT_STATUS.success);
        await setStaticConstants(USER_TYPE.Customer);
        if(isNotifPermission){
         await  requestPushPermission(getLocalStorage('cognitoId'))
          if (pdp_redirect_sku) {
          navigate(PDP_REDIRECT_PATH(pdp_redirect_sku))
          sessionStorage.removeItem('pdp_redirect_sku')
        }
        else navigate(SCAN_ROUTE_PATH)
       }
      else{
        navigate(NOTIFICATION_PERMISSION_PATH);
      }
        //requestPushPermission(getLocalStorage('cognitoId'));
        // addWebSocketSubscription(cognitoUserId);
    
        // if (pdp_redirect_sku) {
        //   navigate(PDP_REDIRECT_PATH(pdp_redirect_sku))
        //   sessionStorage.removeItem('pdp_redirect_sku')
        // }
        // else navigate(SCAN_ROUTE_PATH)
      }
      ).catch((error: any) => {
        setShowLoggedInButton(true);
        console.log("error msg", error);
        showAlert(LOGIN_FAILED, ALERT_STATUS.error);
        //TODO:need to add error notification
      })
    }
  }, [userAttributes?.email]);

  const loginGmailHandler = () => {
    setLocalStorage("loginMethod", "Google");
    signInWithRedirect({
      provider: "Google",
    })
  }

  const loginAppleHandler = () => {
    setLocalStorage("loginMethod", "Apple");
    signInWithRedirect({
      provider: "Apple",
    })
  }

  const setQuoteId = async () => {
    const quoteIdResponse = await doActionPost({ url: apiList.quoteIdURL, userType: USER_TYPE.Customer });
    const quoteId = quoteIdResponse?.data;
    if (quoteId) {
      setLocalStorage("user_quoteId", quoteId);
    } else {
      console.error("Quote ID is undefined or null.");
    }
  }

  useEffect(() => {
    const loginSetup = async () => {
      if (getLocalStorage('user-token')) {
        clearPreviousLoginData("Employee")
        removeLocalStorage('employee_email')
        await setQuoteId()
      }
    }
    loginSetup()
  }, [getLocalStorage('user-token')])

  useEffect(() => {
    setPageToFullHeight(true);
    return (() => {
      setPageToFullHeight(false)
    })
  }, []);


  return (
    <S.Content>
      {!isUserLoggedIn &&
        <div className="login-container">
          <div className="login-container-header">
            <img className="login-logo" src={logo}></img>
            {showLoggedInButton && <>
              <h3 className="login-title">
                {LOGIN_TITLE}
              </h3>
            </>
            }
          </div>
          {showLoggedInButton && <> <div className="login-content">
            <div className="login-button-container">
              <div><button className="login-button" disabled={false} onClick={loginGmailHandler} id="login-gmail"><GoogleIcon /> {GOOGLE_LOGIN_BUTTON_LABEL}</button></div>
             <div><button className="login-button" disabled={false} onClick={loginAppleHandler} id="login-gmail"><AppleIcon />{APPLE_LOGIN_BUTTON_LABEL}</button></div>
              <div className="or-frame">
              <img src={OrFrame} alt="OR" />
            </div>
            <div><button className="login-button" disabled={false} onClick={()=>navigate(LOGIN_WITH_OTP_PATH)} id="login-gmail">{LOGIN_WITH_OTP_LABEL}</button></div>
              <div className="terms-and-policy">
                {LOGIN_TERMS_AND_POLICY}&nbsp;
                <span className="terms-and-conditions" onClick={() => navigate(LOGIN_TERMS_AND_CONDITION_ROUTE_PATH)}>{TERMS_AND_CONDITION_CTA}</span>
                <span>&nbsp;and&nbsp;</span>
                <span className="privacy-policy" onClick={() => navigate(LOGIN_PRIVACY_POLICY_ROUTE_PATH)}>{PRIVACY_AND_POLICY_CTA}</span>
              </div>
            </div>
          </div>
            <div className="employee-cta" onClick={() => navigate(EMPLOYEE_LOGIN_ROUTE_PATH)}>
              <img src={BottomLogoInGrey} alt="Bottom Logo" className="bottom-logo" />
              <span className="employee-login-text">{LOGIN_CONSTANTS.employee_login.en}</span>
            </div>
          </>}
        </div>}

    </S.Content>
  );
};

export default Login;
