import apiList, { ALERT_STATUS, AUTHENTICATION_SUCCESS, DID_NOT_RECEIVE_CODE_HEADING, DONT_HAVE_ACCOUNT_SUBHEADING, LOGIN_BUTTON_Label, LOGIN_FAILED, LOGIN_TITLE, LOGIN_WITH_EMAIL_LABEL, LOGIN_WITH_PHONE_LABEL, OTP_SEND_MSG, OTP_SEND_SUCCESSFUL, OTP_VERIFICATION_FAILED, RESEND_HEADING, SEND_OTP_LABEL, SIGN_UP_HEADING, USER_TYPE } from "../../../../lib/constant";
import * as S from "../styles/styles";
import logo from '../../../../globals/images/tresori-golden-logo.jpg';
import OrFrame from '../../../../globals/svg/orFrame.svg';
import { useEffect, useState } from "react";
import { confirmSignIn, fetchUserAttributes, getCurrentUser, resendSignUpCode, signIn } from "aws-amplify/auth";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../../../../helpers/localStorageUtil";
import { manageIndexedDB } from "../../../../helpers/indexedDB";
import { UserAttributeInterface } from "../types/types";
import { useNavigate } from "react-router-dom";
import { requestPushPermission } from "../../../../helpers/NotificationHandler/subscribeNotification";
import { PDP_REDIRECT_PATH } from "../constant";
import { NOTIFICATION_PERMISSION_PATH, SCAN_ROUTE_PATH } from "../../../../routes/routes-constant";
import { useAuth } from "../../../../Context/AuthContext";
import { doActionPost } from "../../../../helpers/httpRequest";
import { setStaticConstants } from "../../../../helpers/utlis";
import { useGlobalState } from "../../../../Context/GlobalStateContext";
import { useNotification } from "../../../../hooks/useNotification";
import { Loading } from "../../../atoms/loading/Loading";
import { Alert } from "@mui/material";

const OtpConfirmation:React.FC<{username:string}> = ({username}) => {
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [userAttributes, setUserAttributes] = useState<UserAttributeInterface>({
        email: "",
        fname: "",
        lname: "",
        phoneNumber:""
      });
      const [cognitoUserId, setCognitoUserId] = useState<String>("");
      const navigate = useNavigate();
      const [showLoggedInButton, setShowLoggedInButton] = useState(true);
      const pdp_redirect_sku = sessionStorage.getItem('pdp_redirect_sku')
      const [isNotifPermission,setIsNotifPermission] = useState(false);
      const { isUserLoggedIn, loginUser } = useAuth();
      const { setCustomerData } = useGlobalState();
      const { showAlert } = useNotification();
      const [otpSendResponse,setOtpSendResponse] = useState<any>();
      const [loading,setLoading] = useState(false);

        const handleChange = (e:any, index:any) => {
            const newOtp = [...otp];
            const value = e.target.value;

            if (/^[0-9]$/.test(value) || value === '') {
            newOtp[index] = value; 
            setOtp(newOtp);

            if (value && index < 5) {
                const nextInput = document.getElementById(`otp-input-${index + 1}`);
                if (nextInput) {
                nextInput.focus();
                }
            }
            }
        };

      const otpSubmitHandler = async() =>{
        try{
            setLoading(true);
        const response = await confirmSignIn({ challengeResponse: otp?.join("") });
        setLoading(false);
        console.log("otp send resp",response);
        if(!response?.isSignedIn){
            showAlert(OTP_VERIFICATION_FAILED,ALERT_STATUS?.error);
        }
        else
            setOtpSendResponse(response);
        }
        catch (err:any) {
            setLoading(false);
            showAlert(err?.message, ALERT_STATUS.error);
        }
        

      }

      useEffect(() => {
        if (getLocalStorage('access-token')) {
          removeLocalStorage('access-token');
          manageIndexedDB({ key: 'accessToken', toDelete: true })
          //need to call employee sign out function #todo
        }
        const checkUserAuthentication = async () => {
          try {
            // Check if user is signed in
            const currentUser = await getCurrentUser();
            console.log(currentUser)
            setCognitoUserId(currentUser?.username);
            getUserAttributes();
          } catch (err) {
            setShowLoggedInButton(true);
            // User is not signed in
            console.log("User is not signed in", err);
          }
        };
    
        const getUserAttributes = async () => {
          try {
            const attributes: any = await fetchUserAttributes();
            setUserAttributes({
              email: attributes['email'] || "",
              fname: attributes?.name || "",
              lname: attributes?.family_name || "",
              phoneNumber:attributes?.phone_number||""
            });
            console.log("attribute=>", attributes)
            setShowLoggedInButton(false);
          } catch (error) {
            setShowLoggedInButton(true);
            console.error("Error fetching user attributes:", error);
          }
        };
        checkUserAuthentication();
      }, [otpSendResponse]);

      useEffect(()=>{
        if(window?.Notification?.permission==="granted" || window?.Notification?.permission==="denied"){
          setIsNotifPermission(true);
        }
        },[])
        useEffect(()=>{
          if(isUserLoggedIn && !showLoggedInButton){
            if(isNotifPermission){
              requestPushPermission(getLocalStorage('cognitoId'));
                if (pdp_redirect_sku) {
                        navigate(PDP_REDIRECT_PATH(pdp_redirect_sku))
                        sessionStorage.removeItem('pdp_redirect_sku')
                }
                else navigate(SCAN_ROUTE_PATH)
             }
            else{
              navigate(NOTIFICATION_PERMISSION_PATH);
            }
          }
        },[isUserLoggedIn,showLoggedInButton])
        
        useEffect(() => {
            if (userAttributes?.email && !getLocalStorage('user-token') && !isUserLoggedIn) {
              const payload = {
                "customer": {
                  "email": userAttributes.email,
                  "firstname": userAttributes.fname,
                  "lastname": userAttributes.lname
                },
                "cognito_user_id": cognitoUserId,
                "phone_number":userAttributes?.phoneNumber
              }
        
              setCustomerData(payload);
        
              doActionPost({
                url: apiList.userLogin,
                data: payload
              })?.then(async (resp: any) => {
                setLocalStorage('user-token', resp.data);
                manageIndexedDB({ key: 'userToken', value: resp.data })
                manageIndexedDB({ key: 'accessToken', toDelete: true })
                setLocalStorage("cognitoId", JSON.stringify(cognitoUserId));
                loginUser();
                showAlert(AUTHENTICATION_SUCCESS, ALERT_STATUS.success);
                await setStaticConstants(USER_TYPE.Customer);
                if(isNotifPermission){
                 await  requestPushPermission(getLocalStorage('cognitoId'))
                  if (pdp_redirect_sku) {
                  navigate(PDP_REDIRECT_PATH(pdp_redirect_sku))
                  sessionStorage.removeItem('pdp_redirect_sku')
                }
                else navigate(SCAN_ROUTE_PATH)
               }
              else{
                navigate(NOTIFICATION_PERMISSION_PATH);
              }
    
              }
              ).catch((error: any) => {
                setShowLoggedInButton(true);
                console.log("error msg", error);
                showAlert(LOGIN_FAILED, ALERT_STATUS.error);
                //TODO:need to add error notification
              })
            }
          }, [userAttributes?.email]);

    const resendHandler=async()=>{
        try{
            const response = await signIn({ 
                username:username,
                options: {
                    clientMetadata: {}, // Optional, an object of key-value pairs which can contain any key and will be passed to your Lambda trigger as-is.
                    authFlowType:"CUSTOM_WITHOUT_SRP"
                  }
             });
           setLoading(false);
           if(response?.nextStep?.signInStep==="CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"){
                showAlert(OTP_SEND_SUCCESSFUL,ALERT_STATUS?.success)
           }     
        }
        catch (err:any) {
            setLoading(false);
            showAlert(err?.message, ALERT_STATUS.error);
        }

    }

    return (
        <S.Content>
            {loading && <Loading/>}
            <div className="login-container">
            {/* <div className="login-container-header">
                <img className="login-logo" src={logo}></img>
                <>
                <h3 className="login-title">
                    {LOGIN_TITLE}
                </h3>
                </>
            </div> */}
           <div className="otp-confirmation-sender">
                <div className="otp-send-msg">{OTP_SEND_MSG}</div>
                <div className="otp-input-section">
                    {otp.map((value, index) => (
                        <input
                        key={index}
                        id={`otp-input-${index}`}
                        type="text"
                        value={value}
                        maxLength={1} // Limit input to 1 character
                        onChange={(e) => handleChange(e, index)}
                        className="otp-input"
                        pattern="[0-9]*"
                        />
                    ))}
                </div>
           </div>
            <div className="subtext-container">
                {DID_NOT_RECEIVE_CODE_HEADING}<span className="subtext-heading" onClick={resendHandler}>{RESEND_HEADING}</span>
            </div>
            <div className="submit-button-container">
                <button className="submit-button" disabled={false} onClick={otpSubmitHandler} id="login-gmail">{LOGIN_BUTTON_Label}</button>
            </div>
          </div>
        </S.Content>
    );
}

export default OtpConfirmation;