import styled, { css } from "styled-components";
import { media } from "../../../../helpers/breakpoints";
import LoginBackground from '../../../../globals/images/Background/LoginBackground.svg';

export const Content = styled.div<any>`
  ${(p: any) => {
    return css`
    height: 100vh;
    width: 100%;
    background-image: url(${LoginBackground});
    background-size: cover; /* Ensures image covers the entire container */
    background-position: center; /* Centers the image horizontally and vertically */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .phone-input-container {
  display: flex;
  align-items: center;
  width: 370px; /* Adjust container width */
  height: 50px;
  border-radius: 100px;
  border: 0.5px solid #b6b2a6;
  background-color: #ffffff;
  overflow: hidden; /* To make sure border-radius is applied to all inner elements */
}

.country-code-dropdown {
  max-width: 100px; /* Adjust the width of the dropdown */
  min-width: 80px; /* Prevent it from being too small */
  background-color: #ffffff;
  border-right: 0.5px solid #b6b2a6;
}

.MuiSelect-select {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #1c1c1c;
  padding-left: 10px;
}

.input-fields {
  flex: 1;
  height: 100%;
  border: none;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #1c1c1c;
  padding-left: 10px;
  outline: none;
  background-color: transparent;
}
  .title-dropdown{
  width:100px;
  }
    .input-field{
      width:350px;
      height: 50px;
      border-radius: 100px;
      border: 0.5px solid #b6b2a6;
      background-color:#FFFFFF;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #1C1C1C;
      padding-left:20px;
      outline:none;
    }
      .error-msg{
       font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: red;
      }
       .error-signup-msg{
       font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: red;
      margin-top: 0px;
      }
    .title-field{
    width:100px;
    border:none;
    margin-right:20px;
    }
    .select-field{
     width:100px;
    border:none;
    margin-right:0px !important;
    }
    .name-field{
    width:155px !important; 
    }
    .first-field{
    margin-right:20px;
    }
    .first-title-row{
    display:flex;
    }
    input::placeholder {
       font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #1C1C1C;
    }
    .login-content-section{
      margin-top:24px !important;
    }
    .subtext-container{
     font-weight: 500;
      font-size: 14px;
      line-height: 18.2px;
      color: #4E4E4E;
      margin-top:26px;
      text-align:center;
    }
      .subtext-heading{
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color:#6F6855;
      margin-left: 17px; 
      text-decoration: underline;
     text-underline-offset: 6px; 
      }
     .submit-button-container{
         margin-top: 73px;
        display: flex;
        justify-content: center;
     }
    .submit-button{
      width:184px;
      height: 50px;
      border-radius: 100px;
      border: none;
      background-color:#6F6855;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      text-align:center;
      outline:none;
    }
    .otp-confirmation-sender{
    display:flex;
    justify-content:center;
     margin-top:24px;
     flex-direction:column;
     margin-bottom:73px;
    }
     
    .otp-send-msg{
      font-weight: 500;
      font-size: 14px;
      line-height: 18.2px;
      color: #1C1C1C;
      text-align:center;
      margin-bottom:10px;
    }
    .otp-input-section{
      display:flex;
      justify-content:space-between;
      margin-left: 20px;
    margin-right: 20px;
    }
    .otp-input{
    text-align:center;
    width:41.2px;
      height: 40px;
      border-radius: 10px;
      border: 0.5px solid #b6b2a6;
      background-color:#FFFFFF;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #1C1C1C;
      
      outline:none;
    }
    .login-container{
      width: 100%;
      min-height: var(--main-content-height);
    }
    .login-container-header{
      text-align:center;
      .login-logo{
        padding-top:100px;
        width:130px;
        height:31.99px;
        mix-blend-mode: darken;
      }

      .login-title{
        display:flex;
        justify-content:center;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 110%;
          text-align: center;
          color: #1C1C1C;
          margin-top:98px;
    }
    }
    // .login-content{
    //   margin-top:134px;
    // }
    
    .login-button-container{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;
      position: relative;
      width: 370px;
    
      left: calc(50% - 370px/2);
     margin-top:80px;


      @media screen and ${media.largeMobileAndUpMax}{
        // margin-top:15px;
      }
    }
    .login-button-container .login-button{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 7px 52px;
      gap: 40px;

      width: 370px;
      height: 50px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */
      letter-spacing: 0.03em;

      /* White */
      color: #FFFFFF;

      background: #6F6855;
      border-radius: 100px;
      border: none;

      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }
      .terms-and-policy {
        width: 370px;
        height: 15px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        /* identical to box height */

        /* Sand */
        color: #6F6855;


        /* Inside auto layout */
        flex: none;
        order: 3;
        align-self: stretch;
        flex-grow: 0;

      }

      .terms-and-conditions, .privacy-policy {
        font-family: Poppins;
        font-size: 10px;
        font-weight: 700;
        line-height: 15px;
        text-align: left;
      }
        .user-data{
          display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size:30px;
        }
     
      }

      .employee-cta {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 10px;

        position: absolute;
        width: 140.55px;
        height: 35.55px;
        left: 30px;
        bottom: 30px;
      }
      .bottom-logo {
        /* Group */

        width: 35.55px;
        height: 35.55px;


        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;

      }

      .employee-login-text {
       
        width: 95px;
        height: 18px;

        /* Paragraph 3 */
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height */

        /* Black */
        color: #1C1C1C;


        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 0px;
      }
    
    `
  }}
`
export const TermsAndCondition = styled.div`

.heading{
font-weight: 500;
        font-size: 24px;
        line-height: 26.4px;
}
/* Auto layout */
 
padding: 0px 0px 100px;
gap: 24px;

position: absolute;
width: 370px;
height: 8395px;
left: calc(50% - 370px/2);
margin-top: 32px;
.text-content{
font-weight: 400;
        font-size: 16px;
        line-height: 20.8px;
}
      .toggle-switch-container{
      display: flex;
      justify-content: end;
      column-gap: 8px;
      align-items: center;
      font-weight: 400;
        font-size: 16px;
        line-height: 20.8px;
        color :#646855;
      }
        .arabic-heading{
        display:flex;
        justify-content:flex-end;
        margin-top:20px;
        }
`

export const PrivacyPolicy = styled.div`
.heading{
font-weight: 500;
        font-size: 24px;
        line-height: 26.4px;
}
/* Auto layout */
 
padding: 0px 0px 100px;
gap: 24px;

position: absolute;
width: 370px;
height: 8395px;
left: calc(50% - 370px/2);
margin-top: 32px;
.text-content{
font-weight: 400;
        font-size: 16px;
        line-height: 20.8px;
}
        
      .toggle-switch-container{
      display: flex;
      justify-content: end;
      column-gap: 8px;
      align-items: center;
      font-weight: 400;
        font-size: 16px;
        line-height: 20.8px;
        color :#646855;
      }
        .arabic-heading{
        display:flex;
        justify-content:flex-end;
        margin-top:20px;
        }
`
